import "lightgallery.js"
import "lg-autoplay.js"
import "lg-thumbnail.js"
import "lg-zoom.js"
import "lg-fullscreen.js"
import "picturefill"

let init = () => {
    let $galleries = document.querySelectorAll(".-js-gallery")


    for (let $gallery of [...$galleries]) {
        lightGallery($gallery, {
            selector: ".-js-gallery-item",
            zoom: true,
            download: true,
            fullScreen: true,
            thumbnail: true,
        })
    }
}

export default { init }
