let init = () => {
    let $cart = document.querySelector(".-js-cart")

    if (!$cart) {
        return
    }

    let $cartCount = $cart.querySelector(".-js-cart-count")

    if (!$cartCount) {
        return
    }

    let bindEvents = () => {
        let cartCount = parseInt($cartCount.textContent)
        let $items = $cart.querySelectorAll(".mini_cart_item")

        if (!$items.length) {
            return
        }

        $cart.removeEventListener("DOMSubtreeModified", bindEvents)

        for (let $item of [...$items]) {
            let $quantity = $item.querySelector(".quantity")
            let $remove = $item.querySelector("a.remove")

            if (!$quantity || !$remove) {
                continue
            }

            let quantity = parseInt($quantity.textContent)

            $remove.addEventListener("click", () => {
                $cartCount.textContent = cartCount - quantity
            })
        }
    }

    $cart.addEventListener("DOMSubtreeModified", bindEvents, false)
}

export default {
    init,
}
