import "whatwg-fetch"
import Flickity from "flickity"

let init = () => {
    new InfiniteScroll()
}

class InfiniteScroll {
    constructor() {
        this.page = 1
        this.total = 1
        this.$items = document.querySelector(".-js-infinite-list-items")

        if (!this.$items) {
            return
        }

        this.bindMoreButton()
    }

    bindMoreButton() {
        this.$more = document.querySelector(".-js-infinite-list-more")

        if (!this.$more) {
            return
        }

        this.total = this.$more.getAttribute("data-total") || 1

        this.$more.addEventListener("click", async (e) => {
            e.preventDefault()

            this.$more.classList.add("-load")

            await this.loadNextPage()

            this.$more.classList.remove("-load")

            if (this.page >= this.total) {
                this.$more.classList.add("-hide")
            }
        })
    }

    loadNextPage() {
        if (this.page >= this.total) {
            return false
        }

        let url = new URL(window.location.href)

        url.searchParams.append("ajax", 1)
        url.searchParams.append("paged", this.page + 1)

        return new Promise((resolve, reject) => {
            fetch(url)
                .then((res) => res.text())
                .then((body) => {
                    this.$items.insertAdjacentHTML("beforeend", body)
                    this.page++
                    resolve()
                })
        })
    }
}

export default {
    init,
}
