const modal = {}

modal.init = () => {
    // Bind close elements
    const $closeElements = document.querySelectorAll(".-js-modal-close")
    const closeHandler = function(e) {
        e.preventDefault()
        document.body.classList.remove("-no-scroll")
        this.closest(".c-Modal").classList.remove("-is-visible")
    }

    for (const $close of $closeElements) {
        $close.addEventListener("click", closeHandler)
    }

    // Manage modal open links
    const $openElements = document.querySelectorAll(".-js-modal-link")
    const openHandler = function(e) {
        const selector = this.getAttribute("href")

        // Valid selector
        if (selector[0] !== "#" || selector.length < 2) {
            return
        }

        const $modal = document.querySelector(this.getAttribute("href"))
        e.preventDefault()
        document.body.classList.add("-no-scroll")

        if (!$modal) {
            return
        }

        // Hide others modal
        const $modals = document.querySelectorAll(".c-Modal")
        for (const $m of $modals) {
            $m.classList.remove("-is-visible")
            $m.classList.add("-is-hidden")
        }

        // Remove hidden class
        $modal.classList.remove("-is-hidden")

        // Show current modal
        setTimeout(() => $modal.classList.add("-is-visible"), 1)
    }

    for (const $open of $openElements) {
        $open.addEventListener("click", openHandler)
    }
}

export default modal
