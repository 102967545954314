let init = () => {
    let $selectMenus = document.querySelectorAll(".-js-select-menu")

    for (let $select of [...$selectMenus]) {
        let $label = $select.querySelector(".-js-select-menu-label")

        if (!$label) {
            return
        }

        $label.addEventListener("click", () => {
            if ($select.classList.contains("-open")) {
                $select.classList.remove("-open")
            } else {
                $select.classList.add("-open")
            }
        })

        document.addEventListener("click", (evt) => {
            let $target = evt.target

            do {
                if ($target == $select) {
                    return
                }

                $target = $target.parentNode
            } while ($target)

            $select.classList.remove("-open")
        })
    }
}

export default { init }
