export let debounce = (func, wait, immediate) => {
    var timeout
    return function() {
        var context = this,
            args = arguments
        var later = function() {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

export let throttle = (func, limit) => {
    let inThrottle
    return function() {
        let args = arguments
        let context = this
        if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => (inThrottle = false), limit)
        }
    }
}

export let scrollTo = (element, topOffset) => {
    topOffset = topOffset || 0

    window.scroll({
        behavior: "smooth",
        left: 0,
        top: getOffsetTop(element) - topOffset,
    })
}

export let getOffsetTop = (element) => {
    let offsetTop = 0
    while (element) {
        offsetTop += element.offsetTop
        element = element.offsetParent
    }
    return offsetTop
}

export let fixVhUnits = () => {
    let onResize = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", throttle(onResize, 200))

    onResize()
}
