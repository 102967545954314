import { throttle } from "@/utils/utils"

import "lightgallery.js"
import "lg-autoplay.js"
import "lg-thumbnail.js"
import "lg-zoom.js"
import "lg-fullscreen.js"
import "picturefill"

let init = () => {
    window.addEventListener("load", function(event) {
        setupProductInformations()
    })

    let $productImages = document.querySelectorAll(".-js-product-imgs")

    for (let $block of $productImages) {
        let $productImage = $block.querySelector(".-js-product-img")
        let $gallery = $block.querySelector(".-js-product-gallery")

        if ($productImage && $gallery) {
            setupCarousel($gallery, $productImage)
            setupGallery($gallery, $productImage)
        }
    }
}

let setupProductInformations = () => {
    if (!window.matchMedia("(min-width: 1025px)").matches) {
        return
    }

    let $header = document.querySelector(".-js-header")
    let $leftCol = document.querySelector(".-js-product-left-col")
    let $rightCol = document.querySelector(".-js-product-right-col")
    let $informations = document.querySelector(".-js-product-informations")

    if (!$header || !$leftCol || !$rightCol || !$informations) {
        return
    }

    let adminBarOffset = document.body.classList.contains("admin-bar") ? 32 : 0
    let offset = adminBarOffset + 20

    let onScroll = () => {
        let dimensions = {
            rightCol: $rightCol.getBoundingClientRect(),
            leftCol: $leftCol.getBoundingClientRect(),
            informations: $informations.getBoundingClientRect(),
        }

        let position = window.scrollY
        let isFixedMenu = document.body.classList.contains("-fixed-menu")
        let fixedMenuOffset = isFixedMenu ? $header.offsetHeight : 0
        let top = position + dimensions.rightCol.top - fixedMenuOffset - offset
        let bottom = null

        if ($rightCol.classList.contains("-bottom")) {
            bottom = position + dimensions.informations.top - fixedMenuOffset - offset
        } else {
            bottom = position + dimensions.leftCol.top + dimensions.leftCol.height - dimensions.informations.bottom
        }

        let isTop = position >= top
        let isBottom = position >= bottom

        if (dimensions.leftCol.height <= dimensions.rightCol.height) {
            $rightCol.classList.remove("-fixed", "-bottom")
            $informations.style["width"] = null
        } else if (isBottom) {
            $rightCol.classList.add("-bottom")
            $rightCol.classList.remove("-fixed")
            $informations.style["width"] = dimensions.rightCol.width + "px"
        } else if (isTop) {
            $rightCol.classList.add("-fixed")
            $rightCol.classList.remove("-bottom")
            $informations.style["width"] = dimensions.rightCol.width + "px"
        } else {
            $rightCol.classList.remove("-fixed", "-bottom")
            $informations.style["width"] = null
        }
    }

    window.addEventListener("scroll", throttle(onScroll, 10))

    onScroll()
}

let setupCarousel = ($gallery, $productImage) => {
    let $items = $gallery.querySelectorAll(".-js-product-gallery-item")

    for (let $item of $items) {
        $item.addEventListener("click", (e) => {
            let $img = $item.querySelector("img")

            if (!$img) {
                return
            }

            $productImage.setAttribute("src", $img.getAttribute("src"))
            $productImage.setAttribute("srcset", $img.getAttribute("srcset"))
        })
    }
}

let setupGallery = ($gallery, $productImage) => {
    let elements = []
    let $items = $gallery.querySelectorAll(".-js-product-gallery-item img")

    if (!$items.length) {
        return
    }

    for (let $item of $items) {
        elements.push({
            src: $item.getAttribute("src"),
            srcset: $item.getAttribute("srcset"),
            thumb: $item.getAttribute("src"),
        })
    }

    $productImage.addEventListener("click", function() {
        lightGallery($productImage, {
            dynamic: true,
            zoom: true,
            download: true,
            fullScreen: true,
            thumbnail: true,
            dynamicEl: elements,
        })
    })
}

export default {
    init,
}
