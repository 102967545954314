import { fixVhUnits, throttle } from "@/utils/utils"

let init = () => {
    initMenu()
    initSearch()
    fixVhUnits()
    fixHoverBold()
}

let initMenu = () => {
    let $body = document.querySelector("body")
    let $header = document.querySelector("header")
    let $menuItems = document.querySelectorAll(".-js-menu-item")

    if (!$body || !$header || !$menuItems) {
        return
    }

    initFixedMenu($body, $header)
    initResponsiveMenu($body)

    for (let $item of $menuItems) {
        if (!$item.querySelector(".t-Menu__submenu")) {
            continue
        }

        $item.addEventListener("mouseenter", function() {
            $body.classList.add("-menu-open")
        })

        $item.addEventListener("mouseleave", function() {
            $body.classList.remove("-menu-open")
        })
    }
}

let initFixedMenu = ($body, $header) => {
    let onScroll = () => {
        let scrollLength = document.body.scrollHeight
        let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - $header.offsetHeight
        let minScrollLength = 100
        let headerHeightReduction = 55
        let allowedHeight = viewportHeight + minScrollLength + headerHeightReduction

        if (scrollLength < allowedHeight) {
            return
        }

        if (window.scrollY > 20) {
            $body.classList.add("-fixed-menu")
        } else {
            $body.classList.remove("-fixed-menu")
        }
    }

    onScroll()

    window.addEventListener("scroll", throttle(onScroll, 10))
}

let initResponsiveMenu = ($body) => {
    let $toggle = document.querySelector(".-js-menu-toggle")

    if (!$toggle) {
        return
    }

    $toggle.addEventListener("click", (e) => {
        e.preventDefault()

        if ($body.classList.contains("-no-scroll")) {
            $body.classList.remove("-no-scroll", "-show-menu")
        } else {
            $body.classList.add("-no-scroll", "-show-menu")
        }
    })
}

let initSearch = () => {
    let $toggle = document.querySelector(".-js-search-toggle")
    let $input = document.querySelector(".-js-search-input")

    if (!$toggle || !$input) {
        return
    }

    $toggle.addEventListener("click", function() {
        setTimeout(() => {
            $input.focus()
            let value = $input.value
            $input.value = null
            $input.value = value
        }, 100)
    })
}

let fixHoverBold = () => {
    let $items = document.querySelectorAll(".-js-hover-bold-fix")

    for (let $item of $items) {
        $item.setAttribute("data-text", $item.textContent)
    }
}

export default { init }
