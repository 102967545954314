let init = () => {
    window.initMap = () => {
        if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
            loadGoogleMaps()
        } else {
            document.addEventListener("DOMContentLoaded", function() {
                loadGoogleMaps()
            })
        }
    }

    let loadGoogleMaps = () => {
        /*----------  Map  ----------*/

        const $map = document.querySelector(".c-Maps")

        if (!$map) {
            return
        }

        const url = $map.getAttribute("data-url") || false
        const lat = parseFloat($map.getAttribute("data-lat"))
        const lng = parseFloat($map.getAttribute("data-lng"))
        const zoom = parseInt($map.getAttribute("data-zoom")) || 14

        if (!lat || !lng) {
            return
        }

        const coords = { lat, lng }
        const map = new google.maps.Map($map, {
            zoom,
            center: coords,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            streetViewControl: false,
            fullscreenControl: true,
        })

        /*----------  Markers  ----------*/

        const svg =
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 50" width="38" height="50"><path d="M19 48.9a1.26 1.26 0 0 1-1-.54c-2.53-3.68-4.7-6.76-6.54-9.39C3.09 27.09 1.35 24.61 1.35 18.75a17.65 17.65 0 0 1 35.29 0c0 5.88-1.74 8.34-10.08 20.23-1.85 2.63-4 5.71-6.54 9.39a1.24 1.24 0 0 1-1.02.53z" fill="#5e6975"/><path d="M19 27.68a8.93 8.93 0 1 1 8.93-8.93A8.93 8.93 0 0 1 19 27.68z" fill="#4b5661"/></svg>'

        const marker = new google.maps.Marker({
            position: coords,
            map: map,
            title: "Lien vers Google Maps",
            animation: google.maps.Animation.DROP,
            icon: {
                size: new google.maps.Size(38, 50),
                anchor: new google.maps.Point(19, 50),
                url: "data:image/svg+xml;charset=UTF-8;base64," + btoa(svg),
            },
        })

        google.maps.event.addListener(marker, "click", function() {
            if (!url) {
                return
            }
            var win = window.open(url, "_blank")

            if (win) {
                win.focus()
            }
        })
    }
}

export default { init }
