import "./theme.styl"

import DefaultLayout from "@/layouts/default/default.js"
import Gallery from "@/components/gallery/gallery.js"
import Modal from "@/components/modal/modal.js"
import Map from "@/components/map/map.js"
import InfiniteList from "@/components/infinite-list/infinite-list.js"
import SelectMenu from "@/components/select-menu/select-menu.js"
import WoocommerceCart from "@/components/woocommerce/cart.js"
import Product from "@/pages/product/product.js"
import ProductsList from "@/pages/product/products-list.js"

// Init before DOMContentLoaded
DefaultLayout.init()
Map.init()

// Init after DDOMContentLoadedOM
document.addEventListener("DOMContentLoaded", () => {
    Gallery.init()
    Modal.init()
    InfiniteList.init()
    SelectMenu.init()
    WoocommerceCart.init()
    Product.init()
    ProductsList.init()
})
