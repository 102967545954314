import "whatwg-fetch"
import Flickity from "flickity"

let init = () => {
    new ProductsCarousels()
}

class ProductsCarousels {
    constructor() {
        this.$carousels = document.querySelectorAll(".-js-products-list-carousel")

        this.setup()
    }

    setup() {
        for (let $carousel of this.$carousels) {
            $carousel.classList.add("-js-carousel-upgraded")

            let $items = $carousel.querySelectorAll(".-js-products-list-item")

            if ($items.length < 4) {
                return
            }

            let options = {
                groupCells: true,
                contain: true,
                selectedAttraction: 0.01,
                friction: 0.15,
                pageDots: false,
            }

            new Flickity($carousel, options)
        }
    }
}

export default {
    init,
}
